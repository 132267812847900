import { contactForms } from './contactForms'

export const teleportToBody = {
  init() {
    const jsProductModalBoxes = [...document.querySelectorAll('.teleport-to-body')]
    jsProductModalBoxes.forEach(modalBox => {
      if(modalBox.parentNode === document.body) return
      const modalBoxClone = modalBox.cloneNode(true)
      document.body.appendChild(modalBoxClone)
      modalBox.parentNode.removeChild(modalBox)

      document.querySelectorAll('.wpcf7-form').forEach(function(form) {
        wpcf7.init(form);
      });
    })
  }
}
