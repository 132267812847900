export const languages = [
    {
        "id": 194,
        "country": "Slovakia",
        "prefix": "+421",
        "isoCountryCode": "SK",
        "localName": "Slovenčina",
        "localNameSK": "Slovenčina",
        "localNameCZ": "Slovenština"
    },
    {
        "id": 55,
        "country": "Czech Republic",
        "prefix": "+420",
        "isoCountryCode": "CZ",
        "localName": "Čeština",
        "localNameSK": "Čeština",
        "localNameCZ": "Čeština"
    },
    {
        "id": 169,
        "country": "Poland",
        "prefix": "+48",
        "isoCountryCode": "PL",
        "localName": "Poľština",
        "localNameSK": "Poľština",
        "localNameCZ": "Polština"
    },
    {
        "id": 189,
        "country": "Serbia",
        "prefix": "+381",
        "isoCountryCode": "RS",
        "localName": "Srbčina",
        "localNameSK": "Srbčina",
        "localNameCZ": "Srbština"
    },
    {
        "id": 228,
        "country": "United Kingdom",
        "prefix": "+44",
        "isoCountryCode": "GB",
        "localName": "Angličtina",
        "localNameSK": "Angličtina",
        "localNameCZ": "Angličtina"
    }
]
