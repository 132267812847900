import config from '@/config'
import { countries } from '@/assets/js/countriesList'
import { languages } from '@/assets/js/languagesList'
import Choices from 'choices.js'

const CHOICES_TYPES = ['country', 'language', 'phone', 'os']

const getCountriesChoices = (defaultSelected) => {
  const languageKey = `localName${config.language.toUpperCase()}`;
  let countriesObj = countries.map(country => {
    const localName = country[languageKey] || country.localName
    return {
      value: country.isoCountryCode.toLowerCase(),
      label: localName,
      selected: country.isoCountryCode === defaultSelected,
      customProperties: {
        ...country,
        localName,
        icon: '/flags/' + country.isoCountryCode + '.svg',
        isoCountryCode: country.isoCountryCode.toLowerCase()
      }
    }
  })

  countriesObj = countriesObj.sort((a, b) => {
    if (a.value === config.language) {
      return -1
    } else if (b.value === config.language) {
      return 1
    }
    return 0
  })
  return countriesObj;
}

const countriesTemplate = (template) => {
  return {
    item: ({ classNames }, data) => {
      return template(`
      <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}"
      data-item
      data-id="${data.id}"
      data-value="${data.value}"
      ${data.active ? 'aria-selected="true"' : ''}
      ${data.disabled ? 'aria-disabled="true"' : ''}>
      <span class="option__image fi fi-${data.customProperties.isoCountryCode}"></span>
      <span class="country-code">${data.label}</span>
      </div>
      `);
      },
      choice: ({ classNames }, data) => {
      return template(`
        <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}"
          data-choice
          ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
          data-id="${data.id}"
          data-value="${data.value}"
          ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
          <span class="option__image fi fi-${data.customProperties.isoCountryCode}"></span>
          <span class="country-code">${data.label}</span>
        </div>
      `);
    }
  }
}

const getLanguagesChoices = (defaultSelected) => {
  const languageKey = `localName${config.language.toUpperCase()}`;
  let languagesObj = languages.map(language => {
    const localName = language[languageKey] || language.localName
    return {
      value: language.isoCountryCode.toLowerCase(),
      label: localName,
      selected: language.isoCountryCode === defaultSelected,
      customProperties: {
        ...language,
        localName,
        icon: '/flags/' + language.isoCountryCode + '.svg',
        isoCountryCode: language.isoCountryCode.toLowerCase()
      }
    }
  })

  languagesObj = languagesObj.sort((a, b) => {
    if (a.value === config.language) {
      return -1
    } else if (b.value === config.language) {
      return 1
    }
    return 0
  })
  return languagesObj;
}

const languagesTemplate = (template) => {
  return {
    item: ({ classNames }, data) => {
      return template(`
      <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}"
      data-item
      data-id="${data.id}"
      data-value="${data.value}"
      ${data.active ? 'aria-selected="true"' : ''}
      ${data.disabled ? 'aria-disabled="true"' : ''}>
      <span class="option__image fi fi-${data.customProperties.isoCountryCode}"></span>
      <span class="country-code">${data.label}</span>
      </div>
      `);
      },
      choice: ({ classNames }, data) => {
      return template(`
        <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}"
          data-choice
          ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
          data-id="${data.id}"
          data-value="${data.value}"
          ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
          <span class="option__image fi fi-${data.customProperties.isoCountryCode}"></span>
          <span class="country-code">${data.label}</span>
        </div>
      `);
    }
  }
}

const getPhonePrefixesChoices = (defaultSelected) => {
  const languageKey = `localName${config.language.toUpperCase()}`;
  let countriesObj = countries.map(country => {
    const localName = country[languageKey] || country.localName
    return {
      value: country.prefix,
      label: localName,
      selected: country.prefix === defaultSelected,
      customProperties: {
        ...country,
        localName,
        icon: '/flags/' + country.isoCountryCode + '.svg',
        isoCountryCode: country.isoCountryCode.toLowerCase()
      }
    }
  })

  countriesObj = countriesObj.sort((a, b) => {
    if (a.customProperties.isoCountryCode === config.language) {
      return -1
    } else if (b.customProperties.isoCountryCode === config.language) {
      return 1
    }
    return 0
  })
  return countriesObj;
}

const phonePrefixesTemplate = (template) => ({
  item: ({ classNames }, data) => {
    return template(`
      <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}"
            data-item
            data-id="${data.id}"
            data-value="${data.value}"
            ${data.active ? 'aria-selected="true"' : ''}
            ${data.disabled ? 'aria-disabled="true"' : ''}>
            <span class="option__image fi fi-${data.customProperties.isoCountryCode}"></span>
            ${data.value}
      </div>
    `);
  },
  choice: ({ classNames }, data) => {
    return template(`
      <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}"
            data-choice
            ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
            data-id="${data.id}"
            data-value="${data.value}"
            ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
            <div class="option__image fi fi-${data.customProperties.isoCountryCode}"></div>
            <div class="country-name">${data.label}</div>
            <div class="country-prefix">${data.value}</div>
      </div>
    `);
  }
})

const generalTemplate = (template) => ({
  item: ({ classNames }, data) => {
    return template(`
      <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}"
            data-item
            data-id="${data.id}"
            data-value="${data.value}"
            ${data.active ? 'aria-selected="true"' : ''}
            ${data.disabled ? 'aria-disabled="true"' : ''}>
            ${data.label}
      </div>
    `);
  },
  choice: ({ classNames }, data) => {
    return template(`
      <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}"
            data-choice
            ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
            data-id="${data.id}"
            data-value="${data.value}"
            ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
            ${data.label}
      </div>
    `);
  }
})

export const getChoicesInstance = ({ choiceType = 'country', defaultSelected, element, choicesList = [] }) => {
  if(!defaultSelected) {
    console.error(`
      Please provide defaultSelected parameter.
      Should be corresponding with choiceType parameter
      For 'country' choiceType it should be uppercase country code (SK, CZ, UA, UK)
      For 'phone' choiceType it should be phone prefix (+420, +421 etc.)
    `)
    return
  }

  if(!element) {
    console.error(`
      Please provide element parameter. Should be HTMLElement or Vue Element Ref.
    `)
    return
  }

  if(!choiceType) {
    console.error(`Please provide type parameter. Choose from the list ${JSON.stringify(CHOICES_TYPES)} or add new and define its options and template`)
    return
  }

  if(!CHOICES_TYPES.includes(choiceType)) {
    console.error(`
      I don't know choiceType ${choiceType}. Choose from the list ${JSON.stringify(CHOICES_TYPES)} or add new and define its options and template.
    `)
    return
  }

  return new Choices(element, {
    itemSelectText: '',
    searchEnabled: false,
    allowHTML: true,
    shouldSortItems: false,
    shouldSort: false,
    choices: (() => {
      if(choiceType === 'country') {
        return getCountriesChoices(defaultSelected)
      }
      if(choiceType === 'language') {
        return getLanguagesChoices(defaultSelected)
      }
      if(choiceType === 'phone') {
        return getPhonePrefixesChoices(defaultSelected)
      }
      return choicesList
    })(),
    callbackOnCreateTemplates: (() => {
      if(choiceType === 'country') {
        return countriesTemplate
      }
      if(choiceType === 'language') {
        return languagesTemplate
      }
      if(choiceType === 'phone') {
        return phonePrefixesTemplate
      }
      return generalTemplate
    })(),
  })
}

export const getCountryPrefix = (isoCountryCode) => {
  const { prefix } = countries.find(country => country.isoCountryCode === isoCountryCode)
  if(prefix) {
    return prefix
  }
  return config.phonePrefix
}
