<script setup>
// core imports
import { provide, ref, watch, computed, shallowRef } from "vue";
import { useDasStore } from "@/stores/das.store";
import {useGlobalStore} from "@/stores/global.store"
// import components
import DomainSearch from '@/components/das/search-wrap/DomainSearch.vue';
import ToggleArea from '@/components/das/search-wrap/toggle-area/ToggleArea.vue';
import ResultWrap from "@/components/das/popup-wrap/result-wrap/ResultWrap.vue";
import { storeToRefs } from "pinia";
import { onClickOutside } from '@vueuse/core'
import { setElementVisibleWithOffset, setElementsScrollPositionFromTop, getCssVariable } from '@/utils/dom'
import { onUnmounted } from "vue"
import { simpleClone, generateDatalayerDasItem } from "./utils";
import { watchDebounced } from '@vueuse/core';
import { useDataLayer} from '@/dataLayer.js'

const props = defineProps({
  configId: String | Number, // DEPRECATED, not used
  componentId: String | Number,
  opts: String | undefined
})
const conf = ref(null)
const dasRoot = ref(null)
const dasStore = useDasStore();
const globalStore = useGlobalStore();
const {viewItemList} = useDataLayer()

if (!globalStore.tldList) {
  globalStore.tldList = window?.tldList || {}
}
if (!globalStore.configObj['das_default']) {
  globalStore.configObj['das_default'] = window?.dasDefault || null
}

const generalConfig = computed(() => globalStore.getConfigBySlug('general'));

onClickOutside(dasRoot, (event) => {
  const backdrop = document.querySelector('.modal-backdrop')
  if (dasStore.getResults(props.componentId).length && !backdrop) {
    const das = dasStore.getDas(props.componentId)
    das.resultList = [];
    das.onlyFree = false
  }
})

const initDas = () => {
  const defaultConfig = {
    ...simpleClone(globalStore.getConfig('das_default')),
    productType: '',
    productPeriod: '',
    productPack: '',
  }

  let options = {}
  if (props.opts) {
    if (typeof props.opts === 'string') {
      options =  JSON.parse(props.opts.replace(/&quot;/g, '\"'))
    } else {
      options =  props.opts
    }
  }

  const opts = {
    ...defaultConfig,
    ...options
  }


  dasStore.addToDas(props.componentId);
  dasStore.setConfig(props.componentId, defaultConfig, opts)
  conf.value = dasStore.getDas(props.componentId).config
  dasStore.setTldList(props.componentId, conf.value.domain_list)

  dasStore.setUpsaleDomains(props.componentId, opts.upsale_domains)
  dasStore.setPreferredDomain(props.componentId, opts.preferred_domain)
  dasStore.setOnlyFreeToggle(props.componentId, opts.only_free_toggle)

}

// initDas()

const {tldList, configObj} = storeToRefs(globalStore)
watch([tldList, configObj], ([]) => {
  if (tldList.value && configObj.value['das_default']) {
    initDas()
  }
}, { immediate: true });

// provide config data to all child components
provide('config', conf)
provide('componentId', props.componentId)

const handleSetElementVisibleWithOffset = () => {
  const resultWrap = dasRoot.value.querySelector('.b-das__result-wrap')

  if(!resultWrap) {
    throw Error('Element with class .b-das__result-wrap does not exist')
  }

  const topOffset =  Number(getCssVariable({
    element: dasRoot.value,
    variable: (() => {
      if(window.innerWidth < 768) {
        return '--top-viewport-offset-das-mobile'
      }
      if(window.innerWidth >= 768 && window.innerWidth < 1024) {
        return '--top-viewport-offset-das-tablet'
      }

      return '--top-viewport-offset-das-desktop'
    })()
  }))

  const bottomOffset = Number(getCssVariable({
    element: dasRoot.value,
    variable: (() => {
      if(window.innerWidth < 768) {
        return '--bottom-viewport-offset-das-mobile'
      }
      if(window.innerWidth >= 768 && window.innerWidth < 1024) {
        return '--bottom-viewport-offset-das-tablet'
      }

      return '--bottom-viewport-offset-das-desktop'
    })()
  }))

  if(isTouchDevice.value) {
    setElementsScrollPositionFromTop({ element: resultWrap, topOffset })
    return
  }

  setElementVisibleWithOffset({
    element: resultWrap,
    topOffset,
    bottomOffset,
  })
}

const handleFocusOnDas = () => {
  if(!results.value.length) {
    return
  }

  if (dasStore.getDas(props.componentId).transfer) {
    dasStore.switchTransfer(props.componentId)
  }

  handleSetElementVisibleWithOffset()
}

const results = computed(() => dasStore.getDas(props.componentId)?.resultList)

const isTouchDeviceBackdropVisible = shallowRef(false)
const isTouchDevice = computed(() => 'ontouchstart' in window)

const preventDefaultListener = (e) => {
  if (!dasRoot.value.contains(e.target)) {
    e.preventDefault();
  }
};

watch(
  results,
  results => {
    if(isTouchDevice.value) {
      if(!results.length) {
        document.body.classList.remove('overflow-hidden')
        document.body.removeEventListener('touchmove', preventDefaultListener, { passive: false })
        isTouchDeviceBackdropVisible.value = false
      } else {
        document.body.classList.add('overflow-hidden')
        document.body.addEventListener('touchmove', preventDefaultListener, { passive: false })
        isTouchDeviceBackdropVisible.value = true
      }
    }
  }
)

const verifiedDomains = computed(() => dasStore.getDas(props.componentId)?.verifiedDomain)

// DATA LAYER
watchDebounced(
  verifiedDomains,
  () => {
    if (!verifiedDomains.value || verifiedDomains.value.length === 0) return
    const prefix = dasStore.isCdas(props.componentId) ? 'Precheckout ' : ''
    const prefixId = dasStore.isCdas(props.componentId) ? 'precheckout_' : ''
    const items = verifiedDomains.value.map((domain) => generateDatalayerDasItem(domain, generalConfig.value, {}, {prefix, prefixId}))
    viewItemList(prefixId, prefix, items)
  },
  { debounce: 800, deep: true},
)

onUnmounted(() => {
  document.body.classList.remove('overflow-hidden')
})
</script>

<template>
  <div class="b-das__wrapper">
    <div
      v-if="isTouchDevice && isTouchDeviceBackdropVisible"
      @click="() => {
        isTouchDeviceBackdropVisible = false
        dasStore.getDas(props.componentId).resultList = []
      }"
      class="modal-backdrop fade show">
    </div>
    <div
      v-if="conf"
      class="b-das"
      :class="{ 'b-das--has-results': results.length }"
      ref="dasRoot"
    >
      <div
        class="b-das__search-wrap"
        :class="{ 'b-das__search-wrap--allow-hover': !results.length }"
      >
        <DomainSearch
          @onFocus="handleFocusOnDas"
          @onMobileBlur="handleSetElementVisibleWithOffset"
          @onDebouncedInput="handleFocusOnDas"
        />
        <ToggleArea @onSearchTld="handleSetElementVisibleWithOffset" />
      </div>
      <ResultWrap />
    </div>
  </div>
</template>

<style>
.wg-das-hp {
  max-width: 804px;
  margin: 0 auto;
  position: relative;
}
</style>
