<script setup>
import { onMounted, ref, computed } from 'vue'
import { getPriceLabel } from '@/utils/checkout';
import MyButton from '@/stories/Button.vue';
import SummaryScrollableProductList from './SummaryScrollableProductList.vue'
import CouponInput from '@/components/checkout/components/CouponInput.vue'
import { useCheckoutStore } from '@/stores/checkout.store';
import { useAuthStore } from '@/stores/auth.store';
import { openModal } from '@/utils'
import config from '@/config'

const props = defineProps({
  isMobileSticky: {
    type: Boolean,
    default: true
  },
  isHidden: {
    type: Boolean,
    default: false
  }
});

const $checkout = useCheckoutStore();
const $auth = useAuthStore();

const nextStep = () => {
  if ($auth.isLoggedIn) {
    $checkout.setStep($checkout.step + 1)
    $checkout.furthestStep = $checkout.step
    $checkout.isAddProfileOpen = false
  } else {
    summaryCollapseInstance.value.hide()
    openModal('checkoutLoginModal')
  }
}

const summaryCollapse = ref(null)
const summaryCollapseInstance = ref(null)
const isSummaryCollapseCollapsed = ref(true)

const couponCollapse = ref(null)
const couponCollapseInstance = ref(null)
const isCouponCollapseCollapsed = ref(true)

onMounted(() => {
  summaryCollapseInstance.value = new bootstrap.Collapse(summaryCollapse.value, {
    toggle: !isSummaryCollapseCollapsed.value,
  })

  summaryCollapse.value.addEventListener('show.bs.collapse', (event) => {
    if(event.target !== summaryCollapse.value) {
      return
    }
    document.body.classList.add('mobile-overflow-hidden')
    isSummaryCollapseCollapsed.value = false
  })

  summaryCollapse.value.addEventListener('hide.bs.collapse', (event) => {
    if(event.target !== summaryCollapse.value) {
      return
    }
    document.body.classList.remove('mobile-overflow-hidden')
    isSummaryCollapseCollapsed.value = true
  })

  if(couponCollapse.value) {
    couponCollapseInstance.value = new bootstrap.Collapse(couponCollapse.value, {
      toggle: !isCouponCollapseCollapsed.value || $checkout.data.coupon,
    })

    couponCollapse.value.addEventListener('show.bs.collapse', (event) => {
      if(event.target !== couponCollapse.value) {
        return
      }

      isCouponCollapseCollapsed.value = false
    })
    couponCollapse.value.addEventListener('hide.bs.collapse', (event) => {
      if(event.target !== couponCollapse.value) {
        return
      }
      isCouponCollapseCollapsed.value = true
    })
  }
})

const totalPriceWithVat = computed(() => getPriceLabel($checkout.getSummary.totalPrice, { float: 2 }))

const totalPriceWithVatCharactersAmount = computed(() => {
  return totalPriceWithVat.value
    ?.split(' ')
    ?.[0]
    ?.replace('.', '')
    ?.split('')
    ?.length
})
</script>

<template>
  <div
    v-if="isMobileSticky && !isSummaryCollapseCollapsed" class="modal-backdrop fade show d-lg-none"
    @click="summaryCollapseInstance.hide()"
  ></div>
  <div
    class="b-checkout-summary"
    :class="{
      'b-checkout-summary--extended': !isSummaryCollapseCollapsed,
      'b-checkout-summary--is-not-mobile-sticky': !isMobileSticky,
      'b-checkout-summary--is-not-mobile-sticky-step-4': !isMobileSticky && $checkout.step === 4,
      'b-checkout-summary--is-hidden': isHidden
    }"
  >
    <div ref="summaryCollapse" id="summaryCollapse" class="b-checkout-summary__collapse collapse">
      <div class="b-checkout-summary__collapse-inner">
        <div class="b-checkout-summary__header">
          <h2 class="b-checkout-summary__title">
            {{ $checkout.step >= 2 ? $t('checkout.summary.title2') : $t('checkout.summary.title') }}
          </h2>
          <button class="b-checkout-summary__close-button" @click="summaryCollapseInstance.hide()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#7D7F8A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#7D7F8A"/>
            </svg>
          </button>
        </div>
        <template v-if="$checkout.step >= 2">
          <SummaryScrollableProductList :is-summary-mobile-sticky="isMobileSticky" />
        </template>

        <div class="b-checkout-summary__item b-checkout-summary__item--first" :class="{ 'b-checkout-summary__item--price-vat-step-not-1': $checkout.step >= 2 }">
          <div class="b-checkout-summary__item-title">{{ $t('checkout.summary.totalWithoutVat') }}</div>
          <div class="b-checkout-summary__item-value" :class="{'pulse': $checkout.showLoader}">{{ getPriceLabel($checkout.getSummary.priceFinal, { float: 2 }) }}</div>
        </div>
        <div class="b-checkout-summary__item">
          <div class="b-checkout-summary__item-title">{{ $t('checkout.summary.dph') }}</div>
          <div class="b-checkout-summary__item-value" :class="{'pulse': $checkout.showLoader}">{{ getPriceLabel($checkout.getSummary.vat, { float: 2 }) }}</div>
        </div>
        <div v-if="$checkout.data.checkout_total_save" class="b-checkout-summary__item text-orange">
          <div class="b-checkout-summary__item-title">{{ $t('checkout.summary.totalSaved') }}</div>
          <div class="b-checkout-summary__item-value" :class="{'pulse': $checkout.showLoader}">{{ getPriceLabel($checkout.getSummary.discount, { float: 2 }) }}</div>
        </div>
        <!-- <div class="b-checkout-summary__item text-alert-red">
          <div class="b-checkout-summary__item-title">10% dodatočná zľava</div>
          <div class="b-checkout-summary__item-value">-{{ getPriceLabel($checkout.data.checkout_total_save) }}</div>
        </div> -->
        <div
          v-if="$checkout.step === 1"
          class="b-checkout-summary__coupon"
          :class="{ 'b-checkout-summary__coupon--active': !isCouponCollapseCollapsed }"
        >
          <button
            @click.stop="couponCollapseInstance.toggle()"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18" fill="none"><path fill="#051548" fill-rule="evenodd" d="M3.97 6.97a.75.75 0 0 1 1.06 0L9 10.94l3.97-3.97a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"/></svg>
            <span>{{ $t('sale_coupon') }}</span>
          </button>
          <div ref="couponCollapse" id="couponCollapse" class="collapse">
            <CouponInput />
          </div>
        </div>
        <div class="b-checkout-summary__item b-checkout-summary__item--footer">
          <div class="b-checkout-summary__item-title">{{ $t('checkout.summary.total') }}</div>
          <div
            class="b-checkout-summary__item-value"
            :class="{
              'pulse': $checkout.showLoader,
              // 'b-checkout-summary__item-value--high-amounts': totalPriceWithVatCharactersAmount >= 6,
              'b-checkout-summary__item-value--very-high-amounts': totalPriceWithVatCharactersAmount >= 8,
            }"
          >
            {{ totalPriceWithVat }}
          </div>
        </div>
      </div>
    </div>
    <div class="b-checkout-summary__actions">
      <button
        class="b-checkout-summary__actions-collapse-handler"
        @click="summaryCollapseInstance.toggle()"
      >
        <p
          class="b-checkout-summary__actions-collapse-handler-title"
          :class="{ 'b-checkout-summary__actions-collapse-handler-title--active': !isSummaryCollapseCollapsed }"
        >
          <span>{{ $t('checkout.summary.toPay') }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5919 13.0889C15.2664 13.4144 14.7388 13.4144 14.4133 13.0889L10.0026 8.67818L5.59186 13.0889C5.26642 13.4144 4.73878 13.4144 4.41335 13.0889C4.08791 12.7635 4.08791 12.2359 4.41335 11.9104L9.41335 6.91042C9.73879 6.58498 10.2664 6.58498 10.5919 6.91042L15.5919 11.9104C15.9173 12.2359 15.9173 12.7635 15.5919 13.0889Z" fill="#2C57DE"/>
          </svg>
        </p>
        <p class="b-checkout-summary__actions-collapse-handler-price" :class="{'pulse': $checkout.showLoader}">
          {{ totalPriceWithVat}}
          <span>s dph</span>
        </p>
      </button>
      <MyButton v-if="$checkout.step < 4" @click="nextStep" variant="primary" color="orange" size-mobile="small" size="medium" :label="$t('continue')" />
      <MyButton v-if="$checkout.step === 4" :disabled="$checkout.loading || $checkout.step4EditMode" :loading="$checkout.loading" @click="$checkout.handleSubmitOrder" variant="primary" color="orange"  size="medium" :label="$t('sendOrder')" class="w-100" />
    </div>
  </div>
</template>
